import PropTypes from "prop-types"

const Button = ({ text, onClick, sticky }) => {
    function animateButton(e) {
        e.target.classList.remove('animate')
        void(e.target.offsetHeight)
        e.target.classList.add('animate')
    }

    function clickHandler(e) {
        animateButton(e)
        onClick(e)
    }

    return <button className={sticky ? "bubbly-button more-info-sticky" : "bubbly-button"} onClick={clickHandler}>{text}</button>
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

export default Button
