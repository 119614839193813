import PropTypes from "prop-types"
import Sloth from "./Sloth"
import AutoParagraph from "../utilities/AutoParagraph"

const Fact = ({ text, type, href }) => {
	// If no question specific MoreInfo defined, just go to the more info section
	if (href === "#undefined") {
		href = "#more-info"
	}

	// Set to a random default fact
	const defaultText = [
		"Give me an answer and I'll share my wisdom.",
		"Please pick an answer.",
		"I look forward to your answer.",
		"Pick an answer and I'll tell you your impact."
	]
	text = text || defaultText[Math.floor(Math.random() * defaultText.length)]

	// Set to a random default sloth
	const defaultSloth = [
		"neutral",
		"confused",
		"sleepy",
		"chill"
	]
	type = type || defaultSloth[Math.floor(Math.random() * defaultSloth.length)]

	return (
		<a href={href} className="fact">
			<Sloth type={type} />
			<div className="text"><AutoParagraph text={text} /></div>
		</a>
	)
}

Fact.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string)
	]),
	type: PropTypes.string,
	href: PropTypes.string
}

export default Fact
