import { useState } from "react"
import Nav from "./Nav"
import MoreInfoButton from "./MoreInfoButton"
import MoreInfo from "./MoreInfo"
import Questions from "./Questions"
import Thermometer from "./Thermometer"
import AutoParagraph from "../utilities/AutoParagraph"
import Button from "../utilities/Button"

const Quiz = ({ data, setCurrentState, furthestCategory, setFurthestCategory, score, setScore, currentCategory, setCurrentCategory }) => {
	const [currentPage, setCurrentPage] = useState(0)
	const [currentQuestion, setCurrentQuestion] = useState(0)

	// Create array of category icons for Nav.js
	const categoryIcons = data.reduce((acc, val) => [...acc, val.icon], []).concat(["img/icons/results.png"])

	function nextQuestion(e) {
		if (currentQuestion < data[currentCategory].questions.length - 1) {
			setCurrentQuestion(currentQuestion + 1)
			setCurrentPage(currentPage + 1)
			window.scrollTo(0, 0)
		} else if (currentCategory < data.length - 1) {
			if(furthestCategory < currentCategory + 1) {
				setFurthestCategory(currentCategory + 1)
			}
			setCurrentQuestion(0)
			setCurrentPage(0)
			setCurrentCategory(currentCategory + 1)
			window.scrollTo(0, 0)
		} else {
			setCurrentState("results")
			setFurthestCategory(currentCategory + 1)
			setCurrentQuestion(0)
			setCurrentPage(0)
			setCurrentCategory(currentCategory + 1)
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'instant',
			})
		}
	}

	function previousQuestion(e) {
		if (currentQuestion > 0) {
			setCurrentQuestion(currentQuestion - 1)
			setCurrentPage(currentPage - 1)
			window.scrollTo(0, 0)
		} else if (currentCategory > 0) {
			// As state is not updated immediately, it can go to the wrong question without this workaround
			const newCategory = currentCategory - 1
			setCurrentCategory(newCategory)
			setCurrentQuestion(data[newCategory].questions.length - 1)
			setCurrentPage(data[newCategory].questions.length - 1)
			window.scrollTo(0, 0)
		} else {
			setCurrentState("intro")
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'instant',
			})
		}

	}

	return (
		<div id="quiz">
			<div id="page">
				<div className="container">
					<Nav setCurrentCategory={setCurrentCategory} furthestCategory={furthestCategory} categories={categoryIcons} category={currentCategory} pages={data[currentCategory].questions.length} page={currentPage} setCurrentQuestion={setCurrentQuestion} setCurrentPage={setCurrentPage} setCurrentState={setCurrentState} />

					{/* Category intro */}
					<div id="category-info">
						<h4><center>{data[currentCategory]["name"]}</center></h4>
						<AutoParagraph text={data[currentCategory]["intro"]} />
					</div>

					{/* Questions block */}
					<Questions questions={data[currentCategory].questions} currentQuestion={currentQuestion} setScore={setScore} score={score} />

					{/* Thermometer */}
					<Thermometer score={score} />

					{/* Quiz controls */}
					<div className="quiz-controls">
						<div className="left">
							<Button onClick={previousQuestion} text="Previous" />
						</div>
						<MoreInfoButton href={"#" + data[currentCategory].questions[currentQuestion].moreInfo} />
						<div className="right">
							<Button onClick={nextQuestion} text="Next" />
						</div>
					</div>
				</div>
			</div>
			<div id="more-info">
				<MoreInfo moreInfo={data[currentCategory].moreInfo} category={data[currentCategory].name} />
			</div>
		</div>
	)
}

export default Quiz
