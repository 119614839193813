import PropTypes from "prop-types"
var md5 = require('md5');

// Generate a title from a URL
// For example, https://www.youtube.com/watch?v=123456789 becomes "Visit youtube.com"
function prettyTitle(url) {
    return <span>Visit <strong>{url.replace('https://', '').replace('http://', '').replace('www.', '').split('/')[0]}</strong></span>
}

const MoreInfoSources = ({ sources }) => {
    var MoreInfoSourcesItems
    if (Array.isArray(sources)) {
        MoreInfoSourcesItems = sources.map((source, i) => (
            <a href={source.url || source} target="_blank" rel="noreferrer" className="more-info-source" key={"source" + i}>
                <img src={"img/sources/" + md5(source.url || source) + ".png"} alt="" />
                {source.title || prettyTitle(source)}
            </a>
        ))
    } else {
        MoreInfoSourcesItems = (
            <a href={sources.url || sources} target="_blank" rel="noreferrer" className="more-info-source">
                <img src={"img/sources/" + md5(sources.url || sources) + ".png"} alt="" />
                {sources.title || prettyTitle(sources)}
            </a>
        )
    }

    return (
        <div className="more-info-sources">
            {MoreInfoSourcesItems}
        </div>
    )
}

MoreInfoSources.propTypes = {
    sources: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired
}

export default MoreInfoSources
