import Thermometer from "../quiz/Thermometer"
import Button from "../utilities/Button"
import MoreInfo from "../quiz/MoreInfo"

const Results = ({ setCurrentState, data, score, setCurrentCategory, defaultScore }) => {

	// Return index of ranking from best category to worst
	function rankings(scores) {
		const sorted = [...new Set(scores)].sort((a, b) => b - a)
		const rank = new Map(sorted.map((x, i) => [x, i]))
		const rankings = scores.map((x) => rank.get(x))
		const result = []
		const indexes = [...Array(rankings.length).keys()]
		for (const index of indexes) {
			rankings.forEach(function (rank, i) {
				if (rank === index) {
					result.push(i)
				}
			})
		}
		return result
	}

	function adj1(score) {
		if (score >= 4.8 && score <= 5.2) {
			return "around average"
		} else if (score > 5.2) {
			return "not that great"
		} else if (score < 2.0) {
			return "excellent"
		} else if (score < 4.8) {
			return "good"
		}
	}

	function introRecommendation(name, score) {
		const sentences = [
			"Overall in this category, you did " + adj1(score) + ".",
			"You did " + adj1(score) + " in " + name + ".",
			"In " + name + ", you did " + adj1(score) + "."
		]
		return sentences[Math.floor(Math.random() * sentences.length)]
	}

	function questionRecommendations(category) {
		// Get question scores
		const questionScores = new Array(category.questions.length).fill(defaultScore);
		category.questions.forEach(function (question, i) {
			if (typeof question.input.state !== undefined) {
				if (question.input.type === "slider") {
					const option = question.input.options[question.input.state[0]]
					if (!isNaN(option?.weight)) {
						questionScores[i] += option?.weight
					}
				} else {
					const option = question.input.options.find(item => { return item.text === question.input.state[0] })
					if (!isNaN(option?.weight)) {
						questionScores[i] += option?.weight
					}
				}
			}
		})

		// Rank questions from most impactful to least
		const questionRanks = rankings(questionScores)

		// Show top 3 recommendations
		const recommendations = questionRanks.slice(0, 3).map((rank) => {
			if (category.questions[rank].recommendation) {
				return (
					<li>{category.questions[rank].recommendation}</li>
				)
			} else {
				return ""
			}
		})

		return <ul>{recommendations}</ul>
	}

	// Generate general advice
	var thermometerExplanation = ""
	var final = ""
	if (score >= 4.8 && score <= 5.2) {
		thermometerExplanation = "You are approximately equivalent to the average person - but this is not a good thing! You are still " + (score - 2) + " tonnes away from the 2 tonnes Paris agreement target. Below you can read about your top 3 areas for improvement."
		final = "disappointed"
	} else if (score > 5.2) {
		thermometerExplanation = "Unfortunately you are far beyond both the Paris agreement target and an average person's emissions. This usually means that there are large lifestyle changes you must take, however multiple small adjustments do add up and can help in reducing your carbon footprint. Please read about your top 3 areas for improvement below."
		final = "angry"
	} else if (score <= 2.0) {
		thermometerExplanation = "You are doing extremely well, hitting the 2 tonnes Paris agreement target. There are always ways to improve however, even if they are small. Read about your top 3 areas for improvement below."
		final = "in love"
	} else if (score < 4.8) {
		thermometerExplanation = "You are doing better than average, but not quite hitting the 2 tonnes Paris agreement target yet. Keep up the good work and read about your top 3 areas for improvement below."
		final = "happy"
	}

	// Get category scores
	const scores = new Array(data.length).fill(defaultScore);
	data.forEach(function (category, i) {
		for (const question of category.questions) {
			if (typeof question.input.state !== undefined) {
				if (question.input.type === "slider") {
					const option = question.input.options[question.input.state[0]]
					if (!isNaN(option?.weight)) {
						scores[i] += option?.weight
					}
				} else {
					const option = question.input.options.find(item => { return item.text === question.input.state[0] })
					if (!isNaN(option?.weight)) {
						scores[i] += option?.weight
					}
				}
			}
		}
	})

	// Rank categories from best to worst
	const ranks = rankings(scores)

	// Create recommendations for each category
	const recommendations = ranks.slice(0, 3).map((rank, index) => {
		return (
			<div className="recommendation">
				<h4>{data[rank].name}</h4>
				{(index === 0 &&
					<p>{data[rank].name} has been identified as the area you can improve the most.</p>
				) ||
					<p>{introRecommendation(data[rank].name, scores[rank])}</p>
				}
				{questionRecommendations(data[rank])}
				{/* <p><a onClick={() => {
							setCurrentCategory(rank)
							setCurrentState("quiz")
							window.scrollTo({
								top: 0,
								left: 0,
								behavior: 'instant',
							})
						}} href="#more-info">Read More about {data[rank].name}</a></p> */}
				<p><a href={"#" + data[rank].name}>Read More about {data[rank].name}</a></p>
			</div>
		)
	})

	// Generate MoreInfo sections
	const moreInfoItems = data.map((item, index) => {
		return (
			<div>
			<hr />
			<MoreInfo moreInfo={item.moreInfo} category={item.name} hideScrollToTop />
			<br />
			<br />
			</div>
		)
	})

	// Generate chapter links
	const chapters = data.map((item, index) => {return (<li><a href={"#" + item.name}>{item.name}</a></li>)})

	return (
		<div id="intro">
			<div id="page">
				<div id="intro" className="container">
					<h1>Time to see if you're on target...</h1>

					<Thermometer score={score} labels defaultScore={defaultScore} />
					<p>{thermometerExplanation}</p>

					<div className="row">
						<div className="column">
							{recommendations}
						</div>
						<div className="column final">
							<img src={"img/final/" + final + ".png"} alt="" className="final" />
							{/* <p>The sloth has judged you and ended up {final}.</p> */}
						</div>
					</div>
					<center>
						<Button onClick={() => {
							setCurrentCategory(0)
							setCurrentState("quiz")
							window.scrollTo({
								top: 0,
								left: 0,
								behavior: 'instant',
							})
						}} text="Return to Quiz" />
					</center>
				</div>
			</div>
			<div id="more-info">
				<div className="more-info-chapters"><ul>{chapters}</ul></div>
				<div>
					<div className="container">
						<h2><center>Collated Information</center></h2>
						<p><center>Here you can find all the information found throughout the quiz.</center></p>
						<p><center>Click the links in the recommendation section above to learn more about your most impactful areas to improve, or use the chapters on the left to jump to your desired area.</center></p>
					</div>
					{moreInfoItems}
				</div>
				<Button onClick={() => {window.scrollTo({top: 0, left: 0})}} text="Scroll to top" sticky />
			</div>
		</div>
	)
}

export default Results
