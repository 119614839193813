import PropTypes from "prop-types"
import Error from "../utilities/Error"
import Buttons from "./inputs/Buttons"
import Dropdown from "./inputs/Dropdown"
import Slider from "./inputs/Slider"

const Question = ({ text, input, score, setScore }) => {
	switch (input.type) {

		case "buttons":
			return <Buttons text={text} input={input} score={score} setScore={setScore} />

		case "dropdown":
			return <Dropdown text={text} input={input} score={score} setScore={setScore} />

		case "slider":
			return <Slider text={text} input={input} score={score} setScore={setScore} />

		default:
			return <Error text="Question type is not supported." />
	}
}

Question.propTypes = {
	text: PropTypes.string.isRequired,
	input: PropTypes.object.isRequired
}

export default Question
