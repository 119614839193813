import PropTypes from "prop-types"
import AutoParagraph from "../utilities/AutoParagraph"
import MoreInfoSources from "./MoreInfoSources"
import Button from "../utilities/Button"

const MoreInfo = ({ moreInfo, category, hideScrollToTop }) => {

	// Generate MoreInfo sections
	const moreInfoItems = moreInfo.map((item, index) => {
		return (
			// Each heading has an ID for the MoreInfoButton to jump to
			<div key={item.heading} id={item.heading}>
				<div className="container">
					<h3><center>{item.heading}</center></h3>
					{item.img &&
						<img src={item.img} alt="" className="more-info-img" />
					}
					<AutoParagraph text={item.text} />
				</div>

				{item.sources &&
					<div className="more-info-bar">
						<div className="container">
							<h4>Sources and Related Links</h4>
							<MoreInfoSources sources={item.sources} />
						</div>
					</div>
				}
			</div>
		)
	})

	return (
		<div>
			<div className="container">
				<h2 id={category}><center>{category}</center></h2>
			</div>
			{moreInfoItems}
			{hideScrollToTop ||
				<Button onClick={() => {window.scrollTo({top: 0, left: 0})}} text="Scroll to top" sticky />
			}
		</div>
	)
}

MoreInfo.propTypes = {
	moreInfo: PropTypes.array.isRequired
}

export default MoreInfo
