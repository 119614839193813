import Thermometer from "../quiz/Thermometer"
import Fact from "../quiz/Fact"
import Button from "../utilities/Button"

const Intro = ({ setCurrentState, defaultScore }) => {
	return (
		<div id="intro">
			<div id="page">
				<div className="container">
					<h1>Carbon Emissions of Students</h1>
					<ul className="intro-small">
						<p><center>A WebApp with the goal to inform on a more environmentally friendly lifestyle in terms of climate change.</center></p>
						<p><center>The discussed issues are particularly directed at students in and around Switzerland.</center></p>
						<p><center>The presented numbers are not taken as accurate calculations, but rather as a means to offer perspective on certain issues.</center></p>
					</ul>
					<div className="row">
						<div className="column">
							<h3>The sloth on the right will help you as a guide</h3>
							<ul className="intro-small">
								<li>The thermometer below shows how your decisions compare to the average Swiss citizen.</li>
								<li>The target would be the maximal footprint admissible in order to be in line with the goals of the Paris agreement</li>
							</ul>
							<h3>On every page you will find a MORE INFO section</h3>
							<ul className="intro-small">
								<li>Scroll down or click on the corresponding button in order to get to the MORE INFO section.</li>
								<li>It contains further information and links to comprehensive sources.</li>
								<li>Scroll down now to get more general information about this WebApp.</li>
							</ul>
						</div>
						<div className="column">
							<Fact text={["On the following pages you will go through a quiz. Based on your answer the thermostat will go up or down depending on the emissions of the average citizen. So, you always see how you compare to the average.", "You will get some tips and tricks along the way that might help you reduce your individual footpint", "The carbon footprint used here is based on the production-based emissions. To know more scroll down to the MORE INFO section."]} type="chill" />
						</div>
					</div>
					<Thermometer score={defaultScore} labels defaultScore={defaultScore} />
					<center>
						<Button onClick={() => {
							setCurrentState("quiz")
							window.scrollTo({
								top: 0,
								left: 0,
								behavior: 'instant',
							})
						}} text="Start" />
					</center>
				</div>
			</div>
			<div id="more-info">
				<div>
					<div className="container">
						<h2><center>More Info</center></h2>
					</div>
					<div>
						<div className="container">
							<h3>What is this web app about?</h3>
							<p>This tool is trying to be an accessible and tangible way to inform yourself about the environmental impact of your lifestyle and give some tips on how to better yourself with small commitments. First and foremost this webapp is designed with students in mind. It is focussed on the key high emission areas, that are typical for students. Additionally, it is keeping their life circumstances, like for example a limited budget, in mind when giving advice. While the tips given here might therefore not be a perfect fit for others, the impact that certain behaviours have is universal and this webapp is able to provide useful information for everyone.  </p>
							<p>Similarly, while we try to keep the data and tips to be as globally applicable as possible, there is a definitely a bias towards the circumstances and good infrastructure of Switzerland and other comparably developed countries.</p>
							<p>Rather than showing complicated, raw numbers that don't mean that much to most people, as it is the case with most common carbon calculators, we also try a different approach. The goal is to give an idea on which behaviours do have a big impact, as well as show the little things, that might not even be that obvious and which can add up in the long run. </p>
							<p>Awareness is a first step, but we also want to give some pointers how to change things. So for every topic there will be some hints that should help with making some changes to your lifestyle, without it becoming an unsurmountable task.</p>
							<br />
							<h3>Production-based Emissions</h3>
							<p>The carbon footprint of the average Person living in Switzerland in this Webapp is based on the production-based emissions according to the Swiss Federal Office for the Environment. The production-based emissions per capita and year are 5.04 tonnes of CO2 equiv. as of 2020. While production-based emissions only consider the emissions that get created within a country, there is another method of describing the emissions - the consumption-based emissions, which also contain all the emissions of imported goods that were created in other countries. Important to note here, is that the consumption-based emissions of Switzerland are more than double the production-based ones at around 14 tonnes of CO2 equiv. per capita and year. Therefore, depending on what perspective is used, the carbon footprint of the average person living in Switzerland is even way higher than shown in this Webapp. You can find more information on this topic at <a href="https://www.bafu.admin.ch/bafu/de/home/themen/thema-klima/klima--daten--indikatoren-und-karten/klima--indikatoren/indikator-klima.pt.html/aHR0cHM6Ly93d3cuaW5kaWthdG9yZW4uYWRtaW4uY2gvUHVibG/ljL0FlbURldGFpbD9pbmQ9S0wwMDImbG5nPWRlJlBhZ2U9aHR0/cHMlM2ElMmYlMmZ3d3cuYmFmdS5hZG1pbi5jaCUyZmJhZnUlMm/ZkZWZyaXRlbiUyZmhvbWUlMmZ0aGVtZW4lMmZ0aGVtYS10cmFl/Z2Vyc2VpdGUlMmZ0cmFlZ2Vyc2VpdGUtLWRhdGVuLS1pbmRpa2/F0b3Jlbi11bmQta2FydGVuJTJmdHJhZWdlcnNlaXRlLS1pbmRp/a2F0b3JlbiUyZmluZGlrYXRvci10cmFlZ2Vyc2VpdGUucHQuaH/RtbCZTdWJqPU4%3d.html" target="_blank">admin.ch</a></p>
							<br />
							<h3>Target carbon footprint</h3>
							<p>The target for the individual carbon footprint is set at 2 tonnes of CO2 equiv. per capita and year. This number is based on a study conducted by Oxfam in 2021. According to the study, by 2030 the average carbon footprint cannot exceed this number, in order to reach the 1.5°C goal of the Paris Agreement. It has to be mentioned that in a more longterm perspective, this number has to be even lower and should eventually not exceed 0.6 tonnes of CO2 equivalents per capita and year to be sustainable on a global level. You can find more information on the study at <a href="https://www.oxfam.org/en/press-releases/carbon-emissions-richest-1-set-be-30-times-15degc-limit-2030#:~:text=The%20carbon%20footprints%20of%20the,the%20COP26%20meeting%20in%20Glasgow" target="_blank">oxfam.org</a>.</p>
							<br />
							<h3>Disclaimer</h3>
							<p>There is a lot of data about emissions and a variety of sources available. Yet there a lot of variables to them.</p>
							<p>What year is the data from? What country are we talking about, when comparing average emissions? How accurate are these measurements? Is the source perfectly trustworthy or is there some bias involved?</p>
							<p>While building this tool we tried our very best to account for all these factors, but in the end there is no 100% guarantee. We gave our best judgement to only include trusted and objective sources and when we give example numbers, we are trying to use numbers that are universally applicable. However, as we can only give these very generalised estimations we always suggest to do your own research for more details on any specific numbers and not to blindy trust us or anyone else.</p>
							<br />
							<h3>Data Privacy</h3>
							<p>Only you can see your results. Your data will NOT be recorded.</p>
							<br />
							<span className="legal">
								<h3>Attribution</h3>
								<p>Sloth (favicon) credit to Twitter, Inc and other contributors under the CC-BY 4.0 license. See <a href="https://github.com/twitter/twemoji" target="_blank">https://github.com/twitter/twemoji</a> for more information.</p>
								<p>Sloth (facts) credit to macrovector / Freepik under the Freepik terms of use. See <a href="http://www.freepik.com" target="_blank">http://www.freepik.com</a> for more information.</p>
								<p>Sloth (results) credit to user10320847 / Freepik under the Freepik terms of use. See <a href="http://www.freepik.com" target="_blank">http://www.freepik.com</a> for more information.</p>
							</span>
						</div>
					</div>
					<Button onClick={() => {window.scrollTo({top: 0, left: 0})}} text="Scroll to top" sticky />
				</div>
			</div>
		</div>
	)
}

export default Intro
