import PropTypes from "prop-types"

const Nav = ({ categories, category, pages, page, furthestCategory, setCurrentCategory, setCurrentQuestion, setCurrentPage, setCurrentState }) => {
	// Generate nav bar icons for each category
	const categoriesItems = categories.map((image, index) => {
		if (index <= furthestCategory) {
			// If the category has been viewed before, make it clickable
			return <li key={image.toString()} className={index <= category ? "done" : "clickable"} onClick={() => goToCategory(index)}><img src={image} alt={index} /></li>
		} else {
			return <li key={image.toString()}><img src={image} alt={index} /></li>
		}
	});

	function goToCategory(target) {
		if (target === categoriesItems.length - 1) {
			setCurrentState("results")
		} else {
			setCurrentCategory(target)
			setCurrentQuestion(0)
			setCurrentPage(0)
		}
	}

	return (
		<nav>
			<div className="bar">
				<div className="bar-white" />
				<div className="bar-green" style={{ width: (category / (categories.length - 1) * 100) + (page / pages / (categories.length - 1) * 100) + "%" }} />
			</div>
			<ul className="nav">{categoriesItems}</ul>
		</nav>
	)
}

Nav.propTypes = {
	categories: PropTypes.array.isRequired,
	category: PropTypes.number.isRequired,
	pages: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	furthestCategory: PropTypes.number.isRequired
}

export default Nav
