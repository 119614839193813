import PropTypes from "prop-types"

const Error = ({ text }) => {
	// Set default error
	text = text || "An unexpected error occurred."

	// Log error to browser console
	console.error("Error: " + text)

	// Return error dialog
	return <div className="error"><strong>Error:</strong> {text}</div>
}

Error.propTypes = {
	text: PropTypes.string
}

export default Error
