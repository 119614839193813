import PropTypes from "prop-types"

const Thermometer = ({ score, labels = false, defaultScore }) => {

	// Calculate percent to fill thermometer
	// Thermometer scale has a min and max, so use those as a cut-off point
	function scorePercent(score, min, max) {
		if (score < min) {
			score = min
		} else if (score > max) {
			score = max
		}
		return score / max * 100
	}
	const percent = scorePercent(score, 0, 20)
	const targetPercent = scorePercent(2, 0, 20)
	const averagePercent = scorePercent(defaultScore, 0, 20)

	return (
		<div className="push-bottom">
			<div className="thermometer">
				<span className="user">Your current estimate: <u>{Math.max(Math.round(score * 100) / 100, 0)} tonnes/year</u></span>

				<div className="bar-white" />
				<ul className="nav">
					<li className="done"></li>
				</ul>
				<div className="bar">
					<div className="bar-red" style={{ backgroundImage: "linear-gradient(to right, blue 0%, blue 24px, blue 24px, red " + 20 * 100 / percent + "%, red 100%)", width: percent + "%" }} />
					{labels &&
						<div className="bar-labels">
							<div className="bar-target" style={{marginLeft: targetPercent + "%"}} />
							<div className="bar-average" style={{marginLeft: averagePercent + "%"}} />
						</div>
					}
				</div>

				<ul className="scale">
					<li>5</li>
					<li>10</li>
					<li>15</li>
				</ul>

				<span className="scale-label">Tonnes/year of CO<sub>2</sub> equiv.</span>
			</div>
		</div>
	)
}

Thermometer.propTypes = {
	score: PropTypes.number.isRequired
}

export default Thermometer
