import PropTypes from "prop-types"
import Question from "./Question"
import Fact from "./Fact"

const Questions = ({ questions, currentQuestion, setScore, score }) => {
	if (questions[currentQuestion].input.state[0]) {
		var factText
		var factType
		if (questions[currentQuestion].input.type === "slider") {
			const option = questions[currentQuestion].input.options[questions[currentQuestion].input.state[0]]
			factText = option.fact
			factType = option.sloth
		} else {
			const option = questions[currentQuestion].input.options.find(item => { return item.text === questions[currentQuestion].input.state[0] })
			factText = option.fact
			factType = option.sloth
		}
	}

	return (
		<div className="questions">
			<div className="half">
				<Question text={questions[currentQuestion].text} input={questions[currentQuestion].input} setScore={setScore} score={score} />
			</div>
			<div className="half">
				<Fact text={factText} type={factType} href={"#" + questions[currentQuestion].moreInfo} />
			</div>
		</div>
	)
}

Questions.propTypes = {
	questions: PropTypes.array.isRequired
}

export default Questions
