import "./App.css"
import { useState } from "react"
import Intro from "./components/pages/Intro"
import Quiz from "./components/quiz/Quiz"
import Error from "./components/utilities/Error"
import useData from "./Data"
import Results from "./components/pages/Results"

function App() {

    // Import data
    const data = useData()

    // Set starting CO2 equiv
    const defaultScore = 5

    // Set initial app state to intro
    // currentState is one of: intro | quiz | results
    const [currentState, setCurrentState] = useState("intro") // one of: intro | quiz | results

    // Set states required for nav that should be remembered when going between app states
    const [currentCategory, setCurrentCategory] = useState(0)
    const [furthestCategory, setFurthestCategory] = useState(0)
    // const [furthestPage, setFurthestPage] = useState(4)
    // const [furthestQuestion, setFurthestQuestion] = useState(0)
	const [score, setScore] = useState(defaultScore)

    // Routing logic
    switch (currentState) {

        case "intro":
            return (
                <div id="app">
                    <Intro setCurrentState={setCurrentState} defaultScore={defaultScore} />
                </div>
            )

        case "quiz":
            return (
                <div id="app">
                    <Quiz setCurrentState={setCurrentState} furthestCategory={furthestCategory} setFurthestCategory={setFurthestCategory} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} data={data} score={score} setScore={setScore} />
                </div>
            )

        case "results":
            return (
                <div id="app">
                    <Results setCurrentState={setCurrentState} data={data} score={score} setCurrentCategory={setCurrentCategory} defaultScore={defaultScore} />
                </div>
            )

        default:
            return (
                <div id="app">
                    <div className="container">
                        <Error text={"Invalid currentState: " + currentState} />
                    </div>
                </div>
            )
    }
}

export default App
