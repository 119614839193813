import PropTypes from "prop-types"
import Error from "../../utilities/Error"

const Slider = ({ text, input, score, setScore }) => {
    if (!input.options || input.options.length === 0) {
        return <Error text="No question options provided." />
    }

    function updateValue(e) {
        const oldOption = input.options[input.state[0]]
        const newOption = input.options[e.target.value]
        if (isNaN(newOption.weight)) {
            console.warn("Warning: Invalid question option weight. Defaulting to 0.")
        }
        if (oldOption) {
            setScore(score + (newOption.weight || 0) - (oldOption.weight || 0))
        } else {
            setScore(score + (newOption.weight || 0))
        }
        input.state[1](e.target.value)
    }

    return (
        <div className="question">
            <p><strong>{text}</strong></p>
            <input type="range" min="0" max={input.options.length - 1} onChange={updateValue} onClick={updateValue} value={input.state[0]} />
            {input.state[0] &&
                <div>
                <p className="slider-text">{input.options[input.state[0]].text}</p>
                <p className="slider-weight">{input.options[input.state[0]].weight}t/year of CO<sub>2</sub> equiv.</p>
                </div>
            }
        </div>
    )
}

Slider.propTypes = {
    text: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired
}

export default Slider
