import PropTypes from "prop-types"
import Error from "../../utilities/Error"

const Buttons = ({ text, input, score, setScore }) => {
    if (!input.options || input.options.length === 0) {
        return <Error text="No question options provided." />
    }

    const buttons = input.options.map((option, index) => {
        if (option.text) {
            if (input.state[0] === option.text) {
                return <button className="active" key={index} value={option.text} onClick={updateValue}>{option.text}</button>
            } else {
                return <button key={index} value={option.text} onClick={updateValue}>{option.text}</button>
            }
        } else {
            console.warn("Warning: Empty question option hidden.")
        }
    })

    function updateValue(e) {
        const oldOption = input.options.find(item => { return item.text === input.state[0] })
        const newOption = input.options.find(item => { return item.text === e.target.value })
        if (isNaN(newOption.weight)) {
            console.warn("Warning: Invalid question option weight. Defaulting to 0.")
        }
        if (oldOption) {
            setScore(score + (newOption.weight || 0) - (oldOption.weight || 0))
        } else {
            setScore(score + (newOption.weight || 0))
        }
        input.state[1](e.target.value)
    }

    return (
        <div className="question">
            <p><strong>{text}</strong></p>
            {buttons}
            {input.state[0] &&
                <div>
                <p className="slider-weight">{input.options.find(item => { return item.text === input.state[0] }).weight}t/year of CO<sub>2</sub> equiv.</p>
                </div>
            }
        </div>
    )
}

Buttons.propTypes = {
    text: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired
}

export default Buttons
