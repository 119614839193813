import PropTypes from "prop-types"

const AutoParagraph = ({ text }) => {
	if (Array.isArray(text)) {
		// Seperate into multiple paragraphs
		return text.map((paragraph, i) => <p key={"autoParagraph" + i} dangerouslySetInnerHTML={{ __html: paragraph }} className="auto-paragraph" />)
	} else {
		// Just return the text as a single paragraph
		return <p dangerouslySetInnerHTML={{ __html: text }} className="auto-paragraph" />
	}
}

AutoParagraph.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string)
	]).isRequired
}

export default AutoParagraph
