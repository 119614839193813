import PropTypes from "prop-types"

const MoreInfoButton = ({ href }) => {
	// If no question specific MoreInfo defined, just go to the more info section
	if (href === "#undefined") {
		href = "#more-info"
	}

	return (
		<div className="more-info-button">
			<a href={href}>
				<div className="more-info-triangle" />
				<br />
				<p>More Info</p>
			</a>
		</div>
	)
}

MoreInfoButton.propTypes = {
	href: PropTypes.string.isRequired
}

export default MoreInfoButton
