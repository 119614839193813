import PropTypes from "prop-types"

const Sloth = ({ type }) => {
	return (<img alt={"The " + type + " sloth says..."} src={"img/sloths/" + type + ".png"} />)
}

Sloth.propTypes = {
	type: PropTypes.string.isRequired
}

export default Sloth
