import PropTypes from "prop-types"
import Error from "../../utilities/Error"

const Dropdown = ({ text, input, score, setScore }) => {
    if (!input.options || input.options.length === 0) {
        return <Error text="No question options provided." />
    }

    const dropdownOptions = input.options.map((option, index) => {
        if (option.text) {
            return <option key={index} value={option.text}>{option.text}</option>
        } else {
            console.warn("Warning: Empty question option hidden.")
            return ""
        }
    })

    function updateValue(e) {
        const oldOption = input.options.find(item => { return item.text === input.state[0] })
        const newOption = input.options.find(item => { return item.text === e.target.value })
        if (isNaN(newOption.weight)) {
            console.warn("Warning: Invalid question option weight. Defaulting to 0.")
        }
        if (oldOption) {
            setScore(score + (newOption.weight || 0) - (oldOption.weight || 0))
        } else {
            setScore(score + (newOption.weight || 0))
        }
        input.state[1](e.target.value)
    }

    return (
        <div className="question">
            <p><strong>{text}</strong></p>
            {input.type === "dropdown" &&
                <select value={input.state[0]} onChange={updateValue}>
                    <option></option>
                    {dropdownOptions}
                </select>
            }
        </div>
    )
}

Dropdown.propTypes = {
    text: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired
}

export default Dropdown
